import { LocationType, ProfileTheme } from '@rma/generic/domain/types';

export interface Agent {
  agentCode: string;
  profileUrl: string;
  avatarUrl: string;
  firstName: string;
  lastName: string;
  agencyName?: string;
}

export interface AgentDetails {
  agentCode: string;
  agentTitle: string;
  agentName: string;
  agencyName: string;
  agencyCode: string;
  isAwardWinner: boolean;
  overallStars?: number;
  mobile: string;
  canContact: boolean;
  notes: string;
  theme: ProfileTheme;
  agentProfileUrl: string;
  agencyProfileUrl: string;
  isBranded: boolean;
  isTrustedAgent: boolean;
  isGreatNegotiator: boolean;
  isHighlyRecommended: boolean;
  reviewCount: number;
  latestReviewDate?: Date;
  statistics: Record<AgentStatisticType, AgentStatistics>;
  bestStatistics: BestStatistics[];
}

export interface AgentComparison extends AgentDetails {
  bestTotalReviews: boolean;
  sales: Sales;
  propertyTypes: AgentPropertyTypes[];
}

export interface AgentPropertyTypes {
  propertyType: string;
  quantity: number;
  total: number;
  avg: number;
}

export interface Sales {
  total: number;
  lead: number;
  support: number;
  bestPropertiesSoldTotal: boolean;
  bestPropertiesSoldAsLead: boolean;
  bestPropertiesSoldAsSupport: boolean;
}

export type AgentStatisticType =
  | 'ACTIVE_CAMPAIGNS'
  | 'totaL_SALES_QTY'
  | 'totaL_SALES_VALUE'
  | 'avG_SALE_PRICE'
  | 'AVG_DAYS_ON_MARKET'
  | 'AVG_QUOTE_TO_SALEPRICE'
  | 'RESULTS_DISCLOSED'
  | 'QUOTE_PROVIDED'
  | 'RECOMMENDATIONS'
  | 'POSITIVE_TESTIMONIALS';

export type BestStatistics =
  | 'TotalReviews'
  | 'PropertiesSoldTotal'
  | 'PropertiesSoldAsLead'
  | 'PropertiesSoldAsSupport'
  | 'AvgSalePriceTotal'
  | 'AvgSalePriceForHouse'
  | 'AvgSalePriceForApartment'
  | 'AvgSalePriceForOther';

export interface AgentStatistics {
  listingAgentValue?: number;
  buyerAgentValue?: number;
  sellerAgentValue?: number;
  auctionValue?: number;
  privateValue?: number;
  totalValue?: number;

  byPropertyType: { [type: string]: number };
}

export const SortBy = ['TotalReviews', 'PropertiesSold', 'AverageSalePrice'] as const;
export type SortByType = (typeof SortBy)[number];

export interface Market {
  locationId: number;
  locationType: LocationType;
  name: string;
  displayName: string;
  locationProfileUrl: string;
  marketOrder: number;
}
