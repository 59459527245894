import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { Observable, catchError, map, of } from 'rxjs';
import { ShortlistResult } from '../domain/shortlist';
import { ShortlistStorageService } from './shortlist-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiShortlistStorageService implements ShortlistStorageService {
  private readonly shorlistAgentsByCode = 'users/me/shortlist/agents/code-{code}';

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly rmaUrls: RmaUrlsService,
  ) {}

  public load(): Observable<string[]> {
    return this.httpClient.get<string[]>(this.rmaUrls.apiUrl('users/me/shortlist/agents/codes'));
  }

  public add(agentCode: string): Observable<ShortlistResult> {
    return this.httpClient.put(this.rmaUrls.apiUrl(this.shorlistAgentsByCode.replace('{code}', agentCode)), null).pipe(
      catchError((e) => {
        console.error(e);
        return of({ agentCode, added: true, success: false, error: e });
      }),
      map(() => ({ agentCode, added: true, success: true })),
    );
  }

  public remove(agentCode: string): Observable<ShortlistResult> {
    return this.httpClient.delete(this.rmaUrls.apiUrl(this.shorlistAgentsByCode.replace('{code}', agentCode))).pipe(
      catchError((e) => {
        console.error(e);
        return of({ agentCode, added: false, success: false, error: e });
      }),
      map(() => ({ agentCode, added: false, success: true })),
    );
  }
}
