import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagedList } from '@rma/generic/domain/types';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { Observable } from 'rxjs';
import { AgentDetails, Market, SortByType } from '../domain/agent';

@Injectable({
  providedIn: 'root',
})
export class CompareAgentService {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly rmaUrls: RmaUrlsService,
  ) {}

  public agents(agentCodes: string[], sortBy: SortByType = 'TotalReviews'): Observable<PagedList<AgentDetails>> {
    return this.httpClient.get<PagedList<AgentDetails>>(this.rmaUrls.apiUrl('users/me/shortlist/agents'), {
      params: { agentCodes, sortBy },
    });
  }

  public markets(agentCodes: string[]): Observable<PagedList<Market>> {
    return this.httpClient.get<PagedList<Market>>(this.rmaUrls.apiUrl('users/me/shortlist/agents/markets'), {
      params: { agentCodes },
    });
  }
}
